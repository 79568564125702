import React, {Component, Suspense, lazy} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import AuthMiddleware from './AuthMiddleware';


const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Bank = lazy(() => import('./bank/Bank'));
const BankForm = lazy(() => import('./bank/BankForm'));

const Order = lazy(() => import('./payment/Order'));
const Payment = lazy(() => import('./payment/Payment'));
const UPIPayment = lazy(() => import('./payment/UPIPayment'));
const IMPSayment = lazy(() => import('./payment/IMPSayment'));
const PaymentSuccessful = lazy(() => import('./payment/PaymentSuccessful'));
const OrderExpired = lazy(() => import('./payment/OrderExpired'));
const OrderTimeout = lazy(() => import('./payment/OrderTimeout'));
const PaymentSuccess = lazy(()=> import('./payment/WithdrawalSuccess')) ;
const WithdrawalForm = lazy(() => import('./withdrawal/WithdrawalForm'));

class AppRoutes extends Component {
    render() {
        return (
            <Suspense fallback={<Spinner/>}>
                <Switch>


                    <Route path="/error-pages/error-404" component={Error404}/>
                    <Route path="/error-pages/error-500" component={Error500}/>

                        {/*<Route path="/admin/payment-token/81.247.145.234/" component={Order}/>*/}
                        {/*<Route path="/admin/:userId/:userName/81.247.145.234/:id" component={Order}/>*/}
                        {/*<Route path="/order" component={Order}/>*/}
                        <Route path="/admin/:userId/:userName/81.247.145.234/payment/:id" component={Payment}/>
                        <Route path="/bank" component={Bank}/>
                        <Route path="/bank-form" component={BankForm}/>
                        <Route path="/admin/:userId/:userName/81.247.145.234/upi-payment/:id" component={UPIPayment}/>
                        <Route path="/admin/:userId/:userName/81.247.145.234/imps-payment/:id" component={IMPSayment}/>
                        <Route path="/admin/:userId/:userName/81.247.145.234/payment-successful/:id" component={PaymentSuccessful}/>
                        <Route path="/admin/:userId/:userName/81.247.145.234/order-expired/:id" component={OrderTimeout}/>
                        <Route path="/admin/:userId/:userName/81.247.145.234" component={Order}/>
                        <Route path="/order-faild" component={OrderExpired}/>
                        <Route path='/admin/payout/:userId/:userName/81.247.145.234/withdrawal-success/:id' component={PaymentSuccess}/>
                        <Route path="/admin/payout/:userId/:userName/81.247.145.234" component={ WithdrawalForm } />


                    <Redirect to="/error-pages/error-404"/>
                    {/*<Redirect from="/" to="/dashboard"/>*/}
                </Switch>
            </Suspense>
        );
    }
}

export default AppRoutes;