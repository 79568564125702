import React, {Component} from 'react';
import {Dropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from 'react-router-dom';
import { getUserData } from '../userUtils';

class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errors: {}
        };
    }

    handleLogout = () => {
        const token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('user/logout', this.state)
            .then(response => {
                localStorage.removeItem("isAuthenticated");
                localStorage.removeItem("token");
                localStorage.removeItem("userData");
                window.location.href = '/login';
                //this.props.history.push('/login');
                //window.location.reload();
                toast.success(response.data.meta.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            })
            .catch(error => {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });

    }

    toggleOffcanvas() {
        document.querySelector('.sidebar-offcanvas').classList.toggle('active');
        // document.querySelector('body').classList.toggle('mobi-menu-active');
    }

    toggleRightSidebar() {
        document.querySelector('.right-sidebar').classList.toggle('open');
    }

    toggleOnsearch() {
        document.querySelector('.mobi-search-toggle').classList.toggle('active');
        document.querySelector('.noti-iconbox').classList.toggle('active');
    }

    render() {
    const userData = getUserData();
        return (
            <nav className="navbar p-0 fixed-top d-flex flex-row">
                <ToastContainer/>
                <div className='mobi-menu-icon hide'>
                    <button className="navbar-toggler navbar-toggler-left d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                             class="ficon">
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </button>
                </div>
                <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
                    <Link className="navbar-brand brand-logo" to="/"><img
                        src={require('../../assets/images/sitelogo.png')} alt="logo"/></Link>
                </div>
                <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
                    <div className="toggler-head-btnbox hide">
                        <button className="navbar-toggler align-self-center" type="button"
                                onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
                            <i class="las la-angle-double-right"></i>
                        </button>
                    </div>

                    <div className='marqee-block d-flex v-center'>
                        <div className='marqee-inn d-flex'>
                            <marquee>Lorem ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book, but also the leap into electronic typesetting, remaining essentially unchanged.</marquee>
                            <div class="news-title">
                                <img src={require('../../assets/images/speaker.svg')} alt=""/>
                            </div>
                        </div>
                    </div>

                    {/* <ul className="navbar-nav search-nav w-100">
                        <li className="nav-item w-100">
                            <form className="nav-link mt-2 mt-md-0 d-lg-flex search mobi-search-toggle">
                                <input className="form-control" type="text" placeholder="Search products"/>
                                <button className="search-btn"><i class="las la-search"></i></button>
                            </form>
                        </li>
                    </ul> */}
                    <ul className="navbar-nav navbar-nav-right">
                        {/* <li className='nav-item search-mobi hide'>
                            <div className='noti-iconbox' onClick={this.toggleOnsearch}><i class="las la-search"></i>
                            </div>
                        </li> */}
                        {/* <Dropdown alignRight as="li" className="nav-item help-nav-btnbox">
                            <Dropdown.Toggle className="help-button btn btn-secondary" title='Do you need help?'>
                                <img className="help-icon" src={require('../../assets/images/chat_icon_clr.svg')}
                                     alt=""/><span className='d-none'><Trans>Need help?</Trans></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="navbar-dropdown preview-list create-new-dropdown-menu">
                                <Dropdown.Item href="https://api.whatsapp.com/send?phone=19497888888" target="_blank" className="preview-item">
                                    <div className="drop-icon-item d-flex v-center">
                                        <i class="lab la-whatsapp"></i>
                                        <span><Trans>Whatsapp</Trans></span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
                                    <div className="drop-icon-item d-flex v-center">
                                        <i class="lab la-rocketchat"></i>
                                        <span><Trans>Live chat</Trans></span>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}

                        {/* <Dropdown alignRight as="li" className="nav-item">
                            <Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer"
                                             title="Notifications">
                                <div className='noti-iconbox d-flex'>
                                    <img src={require('../../assets/images/notification.svg')} alt=""/>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list notifications-list">
                                <h6 className="notifi-head mb-0 d-flex v-center">
                                    <span><Trans>Notifications</Trans></span><Link className="view_more ml-auto" to="/">View
                                    More</Link></h6>
                                <div className='media-list scrollable-container'>

                                    <div
                                        className='list-item align-items-center justify-content-between notify-responsive noti-items'>
                                        <div className='me-1 d-flex align-items-start gap-1 mob-me-0'>
                                            <Link className="text-left notification-content" to="/">
                                                <span>We have introduced new features and updates this February to meet your accounting needs. Click to read what's new in Munim ERP.</span>
                                            </Link>
                                        </div>
                                        <div className='remove_icon'>
                                            <i class="las la-times"></i>
                                        </div>
                                    </div>

                                    <div
                                        className='list-item align-items-center justify-content-between notify-responsive noti-items'>
                                        <div className='me-1 d-flex align-items-start gap-1 mob-me-0'>
                                            <Link className="text-left notification-content" to="/">
                                                <span><b>Congratulations</b>, Now you can generate more eWay / eInvoice bill with <b>200 free</b> credits. We have <b>added 200 GST credits</b> to your account. Hurry Up!!! Credits are valid till 25th May 2023.</span>
                                            </Link>
                                        </div>
                                        <div className='remove_icon'>
                                            <i class="las la-times"></i>
                                        </div>
                                    </div>

                                    <div
                                        className='list-item align-items-center justify-content-between notify-responsive noti-items'>
                                        <div className='me-1 d-flex align-items-start gap-1 mob-me-0'>
                                            <Link className="text-left notification-content" to="/">
                                                <span>How to create E-way/E-invoice Portal API User credentials?</span>
                                            </Link>
                                        </div>
                                        <div className='remove_icon'>
                                            <i class="las la-times"></i>
                                        </div>
                                    </div>

                                </div>
                            </Dropdown.Menu>
                        </Dropdown> */}

                        <Dropdown alignRight as="li" className="nav-item ml-2 head-prof-item">
                            <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret ml-0 mr-0">
                                <div className="navbar-profile">
                                    <p className="mb-0 d-none d-sm-block navbar-profile-name">
                                        <span className='name-text'><Trans>{userData.name}</Trans></span>
                                        {/*<span class="user-status">{userData.roleName} </span>*/}
                                    </p>
                                    {/*<img className="img-xs rounded-circle"
                                         src={require('../../assets/images/faces/face15.jpg')} alt="profile"/>*/}
                                    <img className="img-xs rounded-circle"
                                         src={userData.companyLogo} alt="profile"/>
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
                                    <div className="drop-icon-item d-flex v-center">
                                        <i class="las la-user-tie"></i>
                                        <span><Trans>My Profile</Trans></span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item href="/change-password" className="preview-item">
                                    <div className="drop-icon-item d-flex v-center">
                                        <i class="las la-lock"></i>
                                        <span><Trans>Change Password</Trans></span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={this.handleLogout} className="preview-item">
                                    {/*onClick={evt =>evt.preventDefault()}*/}
                                    <div className="drop-icon-item d-flex v-center">
                                        <i class="las la-sign-out-alt"></i>
                                        <span><Trans>Log Out</Trans></span>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ul>
                </div>
                
            </nav>
        );
    }
}

export default Navbar;
